/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled/macro';

import { Link } from 'react-router-dom';

import Flex from '../box-model/Flex';

import Avatar from './avatar/Avatar';

import arrowRightIconUrl from './arrow-right-icon.svg';
import sunwareLogoUrl from './sunware-logo-3x.png';

import ShoppingCartButton from './ShoppingCartButton';
import LanguageSelector from './language-selector/LanguageSelector';

const BackLink = styled.a`
	display: flex;
	flex-direction: row;
	flex: none;
	align-items: center;
	font-family: 'Roboto';
	font-size: 14px;
	color: var(--warm-grey);
	cursor: pointer;
	text-decoration: none;
	margin-right: ${props => props.marginRight};

	transition: color 0.2s ease-in-out, text-decoration 0.2s ease-in-out;
	:hover {
		color: var(--dark-mauve);
		text-decoration: underline;
	}

	img {
		flex: none;
	}
	span {
		flex: none;
	}
`;

const boxBuilderLogoLinkStyles = css`
	z-index: 1;
	margin-left: -1.5px;
`;
const boxBuilderLogoStyles = css`
	margin-top: 18px;
	margin-bottom: 0;

	font-family: 'Roboto';
	font-weight: bold;
	letter-spacing: normal;
	color: var(--pastel-red);

	span {
		font-family: 'Pacifico';
		font-weight: normal;
		font-size: 42px;
		color: var(--dark-mauve);
	}
`;

export default function HeaderBar({
	changeLocale,
	currentLocale,
	locales,
	currentCloset,
	currentUser,
	getTextContent,
	isOnMobile,
	matchesViewClosetPage,
	onLogout,
	onUserNameClick,
	toggleShoppingCartPane
}) {
	const buttonTerugNaarSunware = getTextContent('buttonTerugNaarSunware');

	return (
		<Flex flex="none" flexDirection="column" height={121} width="100%">
			<Flex
				flex="none"
				flexDirection="column"
				alignItems="center"
				height={50}
				backgroundColor="#eeeeee"
			>
				<Flex
					flex="none"
					flexDirection="row"
					justifyContent="space-between"
					width={!isOnMobile ? 1110 : '100%'}
					marginLeft={!isOnMobile ? 0 : '28px'}
				>
					<img src={sunwareLogoUrl} width={139} height={50} alt="Sunware logo" />

					<Flex flex="none" flexDirection="row" spaceHorizontal="16px">
						{locales && (
							<LanguageSelector
								currentLocale={currentLocale}
								changeLocale={changeLocale}
								locales={locales}
							/>
						)}

						<BackLink
							href="https://www.sunware.com/"
							marginRight={!isOnMobile ? '-4px' : '24px'}
						>
							<span>{buttonTerugNaarSunware}</span>

							<img
								src={arrowRightIconUrl}
								width={24}
								height={24}
								alt="arrow right icon"
							/>
						</BackLink>
					</Flex>
				</Flex>
			</Flex>

			<Flex
				flex="none"
				flexDirection="row"
				width={!isOnMobile ? 1110 : '100%'}
				marginLeft={!isOnMobile ? 0 : '28px'}
				alignSelf="center"
				justifyContent="space-between"
			>
				<Link to="/" css={boxBuilderLogoLinkStyles}>
					<h1 css={boxBuilderLogoStyles}>
						<span>Boxy</span>
					</h1>
				</Link>

				<Flex
					flex="none"
					flexDirection="row"
					alignSelf="flex-start"
					marginTop="15px"
					spaceHorizontal="15px"
				>
					{currentUser && (
						<Avatar
							currentUser={currentUser}
							onLogout={onLogout}
							onUserNameClick={onUserNameClick}
						/>
					)}

					{currentCloset && currentCloset.boxes.length > 0 && !matchesViewClosetPage && (
						<ShoppingCartButton
							label={
								currentCloset.boxes.length === 1
									? '1 product'
									: currentCloset.boxes.length + ' producten'
							}
							onClick={toggleShoppingCartPane}
						/>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
}
