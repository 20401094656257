/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { Fragment, useCallback } from 'react';

import currency from 'currency.js';

import Block from '../../box-model/Block';
import Flex from '../../box-model/Flex';
import Button from '../../button/Button';
import ShoppingCartButton from '../../header-bar/ShoppingCartButton';

import Pane from './Pane';

import saveIconUrl from './save/save-icon.svg';

const euro = value => currency(value, { separator: '.', symbol: '', decimal: ',' }).format(true);

const ProductName = styled.span`
	display: table-cell;
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: bold;
	line-height: 1.25;
	padding: 0 15px 0 0;
	color: var(--dark-mauve);
`;
const ProductCount = styled.span`
	display: table-cell;
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 300;
	line-height: 1.25;
	padding: 0 25px 0 0;
	color: var(--dark-mauve);
`;
const ProductPrice = styled.span`
	display: table-cell;
	opacity: 0.5;
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 300;
	line-height: 1.25;
	text-align: right;
	padding: 0 25px 0 0;
	color: var(--warm-grey);
`;
const ProductPriceSymbol = styled(ProductPrice)`
	padding: 0 0 0 0;
`;

const TotalPrice = styled.span`
	display: table-cell;
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: bold;
	line-height: 1.25;
	text-align: right;
	color: var(--warm-grey);
`;
const TotalPriceSymbol = styled(TotalPrice)`
	padding: 0 0 0 0;
`;

const TotalLabel = ProductName;

function Separator() {
	return (
		<Fragment>
			<div
				css={css`
					display: table-row;
					height: 5px;
				`}
			>
				<td />
				<td />
				<td />
				<td />
			</div>
			<div
				css={css`
					display: table-row;
					height: 1px;
					> td {
						background-color: #eeeeee;
					}
				`}
			>
				<td />
				<td />
				<td />
				<td />
			</div>
			<div
				css={css`
					display: table-row;
					height: 10px;
				`}
			>
				<td />
				<td />
				<td />
				<td />
			</div>
		</Fragment>
	);
}

function LineItem({ lineItem, index }) {
	return (
		<div
			css={css`
				display: table-row;
				height: 25px;
			`}
		>
			<ProductName>{lineItem.label}</ProductName>
			<ProductCount>{lineItem.count}x</ProductCount>

			<ProductPriceSymbol>€</ProductPriceSymbol>
			<ProductPrice>{euro(lineItem.price)}</ProductPrice>

			<TotalPriceSymbol>€</TotalPriceSymbol>
			<TotalPrice>{euro(lineItem.totalPrice)}</TotalPrice>

			<input type="hidden" name={`Items[${index}].Id`} value={lineItem.externalId} />
			<input type="hidden" name={`Items[${index}].Quantity`} value={lineItem.count} />
			<input type="hidden" name={`Items[${index}].UnitOfMeasureId`} value="STUKS" />
			{lineItem.barCodes && (
				<input type="hidden" name={`Items[${index}].BarCodes`} value={lineItem.barCodes} />
			)}
		</div>
	);
}

export default function ShoppingCartPane({
	getTextContent,
	currentLocale,
	onClosePaneButtonClick,
	placedProducts
}) {
	const lineItems = placedProducts.reduce((lineItems, placedProduct) => {
		const id = placedProduct.guid;
		const existingLineItem = lineItems.find(lineItem => lineItem.id === id);
		if (existingLineItem) {
			existingLineItem.count += 1;
			let totalPrice = existingLineItem.price * existingLineItem.count;
			existingLineItem.totalPrice = Math.round(totalPrice * 100) / 100;
		} else {
			const lineItem = {
				id,
				externalId: placedProduct.externalId,
				barCodes: placedProduct.barCodes,
				label: `${placedProduct.seriesLabel} ${placedProduct.volume}L ${placedProduct.colourLabel}`,
				count: 1,
				price: placedProduct.price,
				totalPrice: placedProduct.price
			};
			lineItems.push(lineItem);
		}
		return lineItems;
	}, []);

	let totalPrice = lineItems.reduce(
		(totalPrice, lineItem) => (totalPrice += lineItem.totalPrice),
		0
	);
	totalPrice = Math.round(totalPrice * 100) / 100;

	const winkelmandjeTotaal = getTextContent('winkelmandjeTotaal') || 'Totaal';
	const buttonKoopSunware = getTextContent('buttonKoopSunware') || 'Koop op sunware';
	const buttonSlaKastOp = getTextContent('buttonSlaKastOp') || 'Sla je kast op';

	return (
		<Block position="absolute" top="5px" right="5px">
			<Pane onClosePaneButtonClick={onClosePaneButtonClick} width="auto">
				<div
					css={css`
						display: table;
						padding-right: 25px;
					`}
				>
					{lineItems.map((lineItem, index) => (
						<LineItem lineItem={lineItem} key={lineItem.id} index={index} />
					))}

					<Separator />

					<div
						css={css`
							display: table-row;
						`}
					>
						<TotalLabel colspan={3}>{winkelmandjeTotaal}:</TotalLabel>
						<td />
						<td />
						<td />
						<TotalPriceSymbol>€</TotalPriceSymbol>
						<TotalPrice>{euro(totalPrice)}</TotalPrice>
					</div>
				</div>

				<Flex
					flexDirection="row"
					justifyContent="space-between"
					marginTop="10px"
					spaceHorizontal="12px"
				>
					<ShoppingCartButton
						isDisabled={lineItems.length === 0}
						isRegular
						label={buttonKoopSunware}
						onClick={() => {
							window.open(
								'https://www.sunware.com/nl-nl/opbergen/opbergboxen/', 
								'_blank'
							);
						}}
					/>
					<Button
						type="primary"
						label={buttonSlaKastOp}
						iconUrl={saveIconUrl}
						iconAlt="webshop icon"
						tag="div"
						to="/aanpassen/opslaan"
					/>
				</Flex>
			</Pane>
		</Block>
	);
}
