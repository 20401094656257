import React, { useCallback, useRef, useState, useEffect } from 'react';

import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { useDrop } from 'react-dnd';

import { isMobile, isTablet } from 'react-device-detect';

import Helmet from 'react-helmet';

import html2canvas from 'html2canvas';

import auth from './authur';

import Flex from './box-model/Flex';

import HeaderBar from './header-bar/HeaderBar';

import BackgroundContainer from './background-container/BackgroundContainer';

import WelcomePage from './pages/welcome/WelcomePage';
import LoginPage from './pages/login/LoginPage';
import RegisterPage from './pages/register/RegisterPage';
import PasswordForgottenPage from './pages/password-forgotten/PasswordForgottenPage';
import SetPasswordPage from './pages/password-forgotten/SetPasswordPage';
import WizardPage from './pages/wizard/WizardPage';
import MyClosetsPage from './pages/my-closets/MyClosetsPage';
import ConfiguratorPage from './pages/configurator/ConfiguratorPage';

import ProductDragLayer from './product/ProductDragLayer';
import Mobile from './pages/mobile/Mobile';
import ViewClosetPage from './pages/view-closet/ViewClosetPage';

import brownBackgroundUrl from './background-container/brown-background-3x.jpg';
import greenBackgroundUrl from './background-container/green-background-3x.jpg';
import greyBackgroundUrl from './background-container/grey-background-3x.jpg';
import lightBackgroundUrl from './background-container/light-background-3x.jpg';

const NEW_CLOSET = {
	guid: 'NEW',
	boxes: [],
	width: 230,
	height: 210,
	depth: 40,
	totalShelves: 2,
	shelveThickness: 4,

	colour: '',
	backgroundGuid: null,
	closetTextureGuid: null
};

function fetchLocales(setLocales) {
	auth.fetch(`/languages`)
		.then(response => response.json())
		.then(locales => {
			console.log('GET LANGUAGES RESPONSE JSON', locales);

			if (locales && Array.isArray(locales)) {
				setLocales(locales);

				return locales;
			} else {
				console.log('GET LANGUAGES UNEXPECTED RESPONSE JSON', locales);
			}
		})
		.catch(error => {
			console.log('GET LANGUAGES ERROR', error);
		});
}

function fetchAndUpdateTexts(currentLocale, setTexts) {
	auth.fetch(`/text?languageCode=${currentLocale}`)
		.then(response => response.json())
		.then(texts => {
			console.log('GET TEXTS RESPONSE JSON', texts);

			if (texts && Array.isArray(texts)) {
				setTexts(texts);

				return texts;
			} else {
				console.log('GET TEXTS UNEXPECTED RESPONSE JSON', texts);
			}
		})
		.catch(error => {
			console.log('GET TEXTS ERROR', error);
		});
}

function preload(
	currentLocale,
	setLocales,
	setTexts,
	setBackgroundOptions,
	setMaterialOptions,
	setCurrentCloset
) {
	const brownImagePreload = new Image(1110, 608);
	brownImagePreload.src = brownBackgroundUrl;
	const greenImagePreload = new Image(1110, 608);
	greenImagePreload.src = greenBackgroundUrl;
	const greyImagePreload = new Image(1110, 608);
	greyImagePreload.src = greyBackgroundUrl;
	const lightImagePreload = new Image(1110, 608);
	lightImagePreload.src = lightBackgroundUrl;

	fetchLocales(setLocales);
	fetchAndUpdateTexts(currentLocale, setTexts);

	Promise.all([
		auth
			.fetch(`/background?languageCode=${currentLocale}`)
			.then(response => response.json())
			.then(backgroundOptions => {
				// console.log('GET BACKGROUNDS RESPONSE JSON', backgroundOptions);

				if (backgroundOptions && Array.isArray(backgroundOptions)) {
					setBackgroundOptions(backgroundOptions);

					return backgroundOptions;
				} else {
					// console.log('GET BACKGROUNDS UNEXPECTED RESPONSE JSON', backgroundOptions);
				}
			})
			.catch(error => {
				// console.log('GET BACKGROUNDS ERROR', error);
			}),

		auth
			.fetch(`/closettexture?languageCode=${currentLocale}`)
			.then(response => response.json())
			.then(materialOptions => {
				// console.log('GET CLOSET TEXTURES RESPONSE JSON', materialOptions);

				if (materialOptions && Array.isArray(materialOptions)) {
					setMaterialOptions(materialOptions);

					return materialOptions;
				} else {
					// console.log('GET CLOSET TEXTURES UNEXPECTED RESPONSE JSON', materialOptions);
				}
			})
			.catch(error => {
				// console.log('GET CLOSET TEXTURES ERROR', error);
			})
	]).then(([backgroundOptions, materialOptions]) => {
		setCurrentCloset(currentCloset => {
			if (!currentCloset.closetTextureGuid) {
				// console.log('PRELOAD DONE, UPDATE NEW CLOSET');
				return {
					...NEW_CLOSET,

					backgroundGuid: backgroundOptions[0].guid,
					closetTextureGuid: materialOptions[0].guid
				};
			}
			return currentCloset;
		});
	});
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function AppContainer() {
	const history = useHistory();

	const matchesMyClosetsPage = useRouteMatch('/mijn-kasten');
	const matchesConfiguratorPage = useRouteMatch('/aanpassen');
	const matchesViewClosetPage = useRouteMatch('/bekijken');

	const queryParams = useQuery();
	const closetIdFromQuery = queryParams.get('c');
	const localeFromQuery = queryParams.get('l');
	// console.log('CURRENT CLOSET ID FROM QUERY PARAMS', closetIdFromQuery);

	const [texts, setTexts] = useState([]);
	const getTextContent = useCallback(
		key => {
			const text = texts.find(text => text.key === key);
			return text ? text.content : '';
		},
		[texts]
	);

	const [backgroundOptions, setBackgroundOptions] = useState([]);
	const [materialOptions, setMaterialOptions] = useState([]);

	const [currentUser, setCurrentUser] = useState(null);
	const [closets, setClosets] = useState([]);
	const [currentCloset, setCurrentCloset] = useState(NEW_CLOSET);

	const [currentLocale, setCurrentLocale] = useState(localeFromQuery || 'nl-NL');
	const [locales, setLocales] = useState([localeFromQuery || 'nl-NL']);
	const changeLocale = useCallback(isoCode => {
		//
		setCurrentLocale(isoCode);

		fetchAndUpdateTexts(isoCode, setTexts);
	}, []);

	const boxId = useRef(0);

	const updateClosets = useCallback(() => {
		return auth
			.fetch(`/closet?languageCode=${currentLocale}`)
			.then(response => response.json())
			.then(closetsFromBackend => {
				// console.log('GET CLOSETS RESPONSE JSON', closetsFromBackend);

				if (closetsFromBackend && Array.isArray(closetsFromBackend)) {
					const closets = closetsFromBackend.reduce((closets, closetFromBackend) => {
						const closet = { ...closetFromBackend };
						closet.boxes = closet.boxes.map(boxFromBackend => {
							const box = {
								...boxFromBackend,
								...boxFromBackend.product,
								boxId: boxId.current++,
								getSize: product =>
									product.rotated ? product.depth : product.width
							};
							delete box.product;
							return box;
						});

						closets.push(closet);
						return closets;
					}, []);

					// console.log('GET CLOSETS PARSED', closets);

					setClosets(closets);
				} else {
					// console.log('GET CLOSETS UNEXPECTED RESPONSE JSON', closetsFromBackend);
				}
			})
			.catch(error => {
				// console.log('GET CLOSETS ERROR', error);
			});
	}, [currentLocale]);

	const isInitialized = useRef(false);

	useEffect(() => {
		if (isInitialized.current) {
			return;
		}
		isInitialized.current = true;

		preload(
			currentLocale,
			setLocales,
			setTexts,
			setBackgroundOptions,
			setMaterialOptions,
			setCurrentCloset
		);

		// live: https://sunware-boxy.com
		// staging: https://boxy.superbold.nl

		auth.initialize({
			origin: window.location.hostname === 'localhost' ? 'https://sunware-boxy.com' : '',
			authPath: '/oauth/token',
			apiPath: '/api',
			persistenceGet: key => localStorage.getItem(key),
			persistenceSet: (key, val) => localStorage.setItem(key, val),
			persistenceClear: storageKey => localStorage.removeItem(storageKey),
			debug: false
		}).then(
			response => {
				// console.log('AUTHENTICATE/INITIALIZE isAuthenticated?', auth.isAuthenticated());

				if (auth.isAuthenticated()) {
					// console.log('AUTHENTICATE/INITIALIZE > AUTHENTICATED > DO INITIAL GET USER…');

					return auth
						.fetch(`/user?languageCode=${currentLocale}`)
						.then(response => response.json())
						.then(responseJson => {
							// console.log('INITIAL GET USER RESPONSE', responseJson);

							if (
								responseJson &&
								typeof responseJson === 'object' &&
								!responseJson.message
							) {
								setCurrentUser({
									firstName: responseJson.firstName,
									lastName: responseJson.lastName
								});

								updateClosets();
							} else {
								// console.log('INITIAL GET USER UNEXPECTED RESPONSE', responseJson);
							}
						})
						.catch(error => {
							// console.log('INITIAL GET USER ERROR', error);
						});
				} else if (matchesMyClosetsPage || matchesConfiguratorPage) {
					history.push('/');
				}
			},
			error => {
				// console.log('AUTHENTICATE/INITIALIZE ERROR', error);
			}
		);
	}, [currentLocale, history, matchesConfiguratorPage, matchesMyClosetsPage, updateClosets]);

	useEffect(() => {
		if (!closetIdFromQuery) {
			return;
		}

		auth.fetch('/closet/' + closetIdFromQuery + `?languageCode=${currentLocale}`)
			.then(response => response.json())
			.then(closetFromBackend => {
				// console.log('GET CLOSET RESPONSE JSON', closetFromBackend);

				if (closetFromBackend && !closetFromBackend.message) {
					const closet = { ...closetFromBackend };
					closet.boxes = closet.boxes.map(boxFromBackend => {
						const box = {
							...boxFromBackend,
							...boxFromBackend.product,
							boxId: boxId.current++,
							getSize: product => (product.rotated ? product.depth : product.width)
						};
						delete box.product;
						return box;
					});

					// console.log('GET CLOSET PARSED', closet);

					setCurrentCloset(closet);
				} else {
					// console.log('GET CLOSET UNEXPECTED RESPONSE JSON', closetFromBackend);
				}
			})
			.catch(error => {
				// console.log('GET CLOSET ERROR', error);
			});
	}, [closetIdFromQuery, currentLocale]);

	const [isShoppingCartPaneVisible, setIsShoppingCartPaneVisible] = useState(false);
	const toggleShoppingCartPane = useCallback(
		() => setIsShoppingCartPaneVisible(isShoppingCartPaneVisible => !isShoppingCartPaneVisible),
		[]
	);
	const hideShoppingCartPane = useCallback(() => setIsShoppingCartPaneVisible(false), []);
	const [isColorsPaneVisible, setIsColorsPaneVisible] = useState(false);

	const [redInfoPopupIsVisible, setRedInfoPopupIsVisible] = useState(true);

	const handleLogin = useCallback(
		({ email, password }, next) => {
			return auth.authenticate({ username: email, password }).then(
				responseJson => {
					// console.log('LOGIN RESPONSE', responseJson);
					if (responseJson && typeof responseJson === 'object' && !responseJson.error) {
						return auth
							.fetch(`/user?languageCode=${currentLocale}`)
							.then(response => response.json())
							.then(responseJson => {
								// console.log('GET USER RESPONSE JSON', responseJson);

								if (
									responseJson &&
									typeof responseJson === 'object' &&
									!responseJson.message
								) {
									setCurrentUser({
										firstName: responseJson.firstName,
										lastName: responseJson.lastName
									});

									updateClosets().then(() => {
										history.push(next);
									});
								} else {
									// console.log('GET USER UNEXPECTED RESPONSE JSON', responseJson);
								}
							})
							.catch(error => {
								// console.log('GET USER ERROR', error);
							});
					} else {
						// console.log('LOGIN UNEXPECTED RESPONSE', responseJson);
						return Promise.reject(responseJson);
					}
				},
				error => {
					// console.log('LOGIN ERROR', error);
					return error;
				}
			);
		},
		[currentLocale, history, updateClosets]
	);

	const handleRegister = useCallback(
		({ firstName, lastName, email, password }, next) => {
			return auth
				.fetch(`/user?languageCode=${currentLocale}`, {
					method: 'POST',
					body: JSON.stringify({
						languageCode: currentLocale,
						firstName,
						lastName,
						email,
						password
					})
				})
				.then(response => response.json())
				.then(responseJson => {
					// console.log('REGISTER RESPONSE', responseJson);

					if (
						responseJson &&
						typeof responseJson === 'object' &&
						responseJson.result !== 'DuplicateUserName'
					) {
						setCurrentUser({ firstName, lastName });

						history.push(next);
					} else {
						// console.log('REGISTER UNEXPECTED RESPONSE', responseJson);
						return Promise.reject(responseJson);
					}
				})
				.catch(error => {
					// console.log('REGISTER ERROR', error);
					return error;
				});
		},
		[currentLocale, history]
	);

	const handlePasswordForgotten = useCallback(
		({ email }, next) => {
			return auth
				.fetch(`/resetpassword?languageCode=${currentLocale}`, {
					method: 'POST',
					body: JSON.stringify({ languageCode: currentLocale, emailAddress: email })
				})
				.then(response => response.json())
				.then(responseJson => {
					// console.log('PASSWORD FORGOTTEN RESPONSE', responseJson);

					if (
						responseJson &&
						typeof responseJson === 'object' &&
						responseJson.result !== 'NoUserFound'
					) {
						history.push({ pathname: '/inloggen', state: { next } });
					} else {
						// console.log('PASSWORD FORGOTTEN UNEXPECTED RESPONSE', responseJson);
						return Promise.reject(responseJson);
					}
				})
				.catch(error => {
					// console.log('PASSWORD FORGOTTEN ERROR', error);
					return error;
				});
		},
		[currentLocale, history]
	);

	const handleSetPassword = useCallback(
		({ password, t }, next) => {
			auth.fetch(`/resetpassword?languageCode=${currentLocale}`, {
				method: 'PUT',
				body: JSON.stringify({ languageCode: currentLocale, password, t })
			})
				.then(response => response.json())
				.then(responseJson => {
					// console.log('SET PASSWORD RESPONSE', responseJson);

					if (responseJson && typeof responseJson === 'object' && !responseJson.message) {
						history.push({ pathname: '/inloggen', state: { next } });
					} else {
						// console.log('SET PASSWORD UNEXPECTED RESPONSE', responseJson);
						return Promise.reject(responseJson);
					}
				})
				.catch(error => {
					// console.log('SET PASSWORD ERROR', error);
				});
		},
		[currentLocale, history]
	);

	const handleLogout = useCallback(() => {
		auth.signout();

		setCurrentUser(null);

		history.push('/');
	}, [history]);

	const deleteProduct = useCallback(
		productToDelete => {
			setCurrentCloset(currentCloset => {
				return {
					...currentCloset,
					boxes: currentCloset.boxes.filter(
						placedProduct => placedProduct.boxId !== productToDelete.boxId
					),
					isDirty: true
				};
			});
		},
		[setCurrentCloset]
	);

	const rotateProduct = useCallback(
		productToRotate => {
			setCurrentCloset(currentCloset => {
				return {
					...currentCloset,
					boxes: currentCloset.boxes.map(placedProduct => {
						if (placedProduct.boxId !== productToRotate.boxId) {
							return placedProduct;
						}
						return { ...productToRotate, rotated: !productToRotate.rotated };
					}),
					isDirty: true
				};
			});
		},
		[setCurrentCloset]
	);

	const handleBeginDrag = useCallback(product => {
		setIsColorsPaneVisible(false);
		setIsShoppingCartPaneVisible(false);
		setRedInfoPopupIsVisible(false);
	}, []);

	const emptyCurrentCloset = useCallback(() => {
		setCurrentCloset(currentCloset => {
			return {
				...currentCloset,
				isDirty: true,
				boxes: []
			};
		});
		setIsShoppingCartPaneVisible(false);
	}, [setCurrentCloset]);

	const handleClearButtonClick = useCallback(() => {
		emptyCurrentCloset();
	}, [emptyCurrentCloset]);

	const handleDropProduct = useCallback(
		productOrProducts => {
			setCurrentCloset(currentCloset => {
				let newBoxes = [];
				if (Array.isArray(productOrProducts)) {
					newBoxes = productOrProducts;
				} else if (productOrProducts) {
					newBoxes = [...currentCloset.boxes];
					productOrProducts.boxId = boxId.current++;
					newBoxes.push(productOrProducts);
				} else {
					return currentCloset;
				}

				return {
					...currentCloset,
					isDirty: true,
					boxes: newBoxes
				};
			});
		},
		[setCurrentCloset]
	);

	const handleDropProductOutside = useCallback(
		(product, monitor) => {
			if (!monitor.isOver({ shallow: true })) {
				return;
			}
			deleteProduct(product);
		},
		[deleteProduct]
	);

	// eslint-disable-next-line no-unused-vars
	const [_, connectDropTarget] = useDrop({
		accept: ['PLACED_PRODUCT', 'PRODUCT'],
		drop: (item, monitor) => handleDropProductOutside(item, monitor)
	});

	const handleRef = domNode => connectDropTarget(domNode);

	// console.log('CURRENT CLOSET', currentCloset);

	const handleSetBackgroundToColor = useCallback(color => {
		setCurrentCloset(currentCloset => ({
			...currentCloset,
			backgroundGuid: '',
			colour: color,
			isDirty: true
		}));
	}, []);
	const handleSetBackgroundToPhoto = useCallback(backgroundOption => {
		setCurrentCloset(currentCloset => ({
			...currentCloset,
			backgroundGuid: backgroundOption.guid,
			colour: '',
			isDirty: true
		}));
	}, []);

	const reset = useCallback(() => {
		setCurrentCloset({
			...NEW_CLOSET,

			backgroundGuid: backgroundOptions[0] ? backgroundOptions[0].guid : null,
			closetTextureGuid: materialOptions[0] ? materialOptions[0].guid : null
		});
		setIsShoppingCartPaneVisible(false);
	}, [backgroundOptions, materialOptions]);

	const handleUserNameClick = useCallback(() => {
		reset();

		history.push('/mijn-kasten');
	}, [history, reset]);

	const editCloset = useCallback(
		closet => {
			setCurrentCloset(closet);
			setIsShoppingCartPaneVisible(false);

			history.push('/aanpassen/boxen');
		},
		[history]
	);

	const deleteCloset = useCallback(
		closetToDelete => {
			return auth
				.fetch(`/closet/${closetToDelete.guid}?languageCode=${currentLocale}`, {
					method: 'DELETE'
				})
				.then(response => response.json())
				.then(responseJson => {
					// console.log('DELETE CLOSET response', responseJson);
					if (responseJson && responseJson.result === 'Success') {
						setClosets(closets =>
							closets.filter(closet => {
								return closet.guid !== closetToDelete.guid;
							})
						);
					} else {
						// console.log('UNEXPECTED DELETE CLOSET RESPONSE', responseJson);
						return Promise.reject(responseJson);
					}
				})
				.catch(error => {
					// console.log('DELETE CLOSET ERROR', error);
				});
		},
		[currentLocale]
	);

	const saveCloset = useCallback(
		closetName => {
			const dataToSave = {
				...currentCloset,
				boxes: currentCloset.boxes.reduce((boxesToSave, product) => {
					const boxToSave = {
						x: product.x,
						y: product.y,
						rotated: product.rotated || false,
						createDate: product.createDate || new Date().toISOString(),
						productGuid: product.guid
					};

					const productToSave = { ...product };
					delete productToSave.x;
					delete productToSave.y;
					delete productToSave.rotated;
					delete productToSave.getSize;
					delete productToSave.width;
					delete productToSave.height;
					delete productToSave.depth;
					delete productToSave.boxId;

					boxToSave.product = productToSave;

					boxesToSave.push(boxToSave);

					return boxesToSave;
				}, []),
				guid: currentCloset.guid === 'NEW' ? undefined : currentCloset.guid,
				name: closetName,
				colour: currentCloset.colour || '',
				backgroundImage: currentCloset.backgroundImage || ''
			};

			delete dataToSave.isDirty;

			// console.log('SAVING CLOSET…');

			return auth
				.fetch(`/closet?languageCode=${currentLocale}`, {
					method: 'POST',
					body: JSON.stringify({ ...dataToSave, languageCode: currentLocale })
				})
				.then(response => response.json())
				.then(responseJson => {
					// console.log('SAVE CLOSET response', responseJson);
					if (responseJson.guid && !responseJson.message) {
						const updatedCloset = {
							...currentCloset,
							guid: responseJson.guid,
							lastModifiedDate:
								responseJson.lastModifiedDate || new Date().toISOString(),
							name: closetName,
							isDirty: false
						};
						// console.log('SAVE SUCCESS > GET LATEST CLOSETS');

						updateClosets().then(() => {
							setCurrentCloset(updatedCloset);
						});
					} else {
						// console.log('UNEXPECTED SAVE CLOSET RESPONSE', responseJson);
					}
				})
				.catch(error => {
					// console.log('SAVE CLOSET ERROR', error);
				});
		},
		[currentCloset, currentLocale, updateClosets]
	);

	const screenshotRef = useRef();
	const sideMenuRef = useRef();
	const savePaneRef = useRef();

	const generateDownloadablePDF = useCallback(
		guid => {
			const old = isShoppingCartPaneVisible;
			setIsShoppingCartPaneVisible(false);

			sideMenuRef.current.style.visibility = 'hidden';
			savePaneRef.current.style.visibility = 'hidden';

			setTimeout(() => {
				html2canvas(screenshotRef.current).then(function(canvas) {
					canvas.toBlob(imageBlob => {
						var form = new FormData();
						form.append('closetImage', imageBlob);

						// console.log('GENERATE PDF, CLOSET GUID', guid);

						auth.fetch('/pdf/?guid=' + guid + `&languageCode=${currentLocale}`, {
							method: 'POST',
							headers: {
								responseType: 'application/pdf'
							},
							body: form
						})
							.then(response => response.blob())
							.then(pdfBlob => {
								// console.log('GENERATED PDF SIZE', pdfBlob.size);

								setIsShoppingCartPaneVisible(old);

								sideMenuRef.current.style.visibility = 'visible';
								savePaneRef.current.style.visibility = 'visible';

								var link = document.createElement('a');
								link.href = window.URL.createObjectURL(pdfBlob);
								link.download = 'Sunware_BoxBuilder_' + new Date() + '.pdf';
								link.click();
							})
							.catch(error => {
								// console.log('GENERATE PDF ERROR', error);
							});
					});
				});
			}, 500);
		},
		[currentLocale, isShoppingCartPaneVisible]
	);

	// if (isMobile && !isTablet) {
	// 	return <Mobile currentLocale={currentLocale} getTextContent={getTextContent} />;
	// }

	return (
		<Flex flex="1" flexDirection="column" alignItems="center" overflow="auto" ref={handleRef}>
			<HeaderBar
				currentLocale={currentLocale}
				changeLocale={changeLocale}
				locales={locales}
				currentCloset={currentCloset}
				currentUser={currentUser}
				getTextContent={getTextContent}
				matchesViewClosetPage={matchesViewClosetPage}
				onLogout={handleLogout}
				onUserNameClick={handleUserNameClick}
				toggleShoppingCartPane={toggleShoppingCartPane}
			/>

			<Helmet>
				<meta
					name="viewport"
					content="width=1175, height=727, initial-scale=1, shrink-to-fit=yes"
				/>
			</Helmet>

			<Flex
				flex="none"
				flexDirection="column"
				paddingBottom="25px"
				width={1110}
				ref={screenshotRef}
			>
				<Switch>
					<Route path="/">
						<BackgroundContainer
							backgroundOptions={backgroundOptions}
							currentCloset={currentCloset}
						/>

						<Route path="/" exact>
							<WelcomePage
								currentUser={currentUser}
								reset={reset}
								getTextContent={getTextContent}
							/>
						</Route>

						<Route path="/inloggen" exact>
							<LoginPage onSubmit={handleLogin} getTextContent={getTextContent} />
						</Route>

						<Route path="/registreren" exact>
							<RegisterPage
								onSubmit={handleRegister}
								getTextContent={getTextContent}
							/>
						</Route>

						<Route path="/wachtwoord-vergeten" exact>
							<PasswordForgottenPage
								onSubmit={handlePasswordForgotten}
								getTextContent={getTextContent}
							/>
						</Route>

						<Route path="/wachtwoord-instellen" exact>
							<SetPasswordPage
								onSubmit={handleSetPassword}
								getTextContent={getTextContent}
							/>
						</Route>

						<Route path="/beginnen" exact>
							<WizardPage
								auth={auth}
								currentLocale={currentLocale}
								getTextContent={getTextContent}
								setCurrentCloset={setCurrentCloset}
							/>
						</Route>

						<Route path="/mijn-kasten" exact>
							<MyClosetsPage
								closets={closets}
								deleteCloset={deleteCloset}
								editCloset={editCloset}
								generateDownloadablePDF={generateDownloadablePDF}
								getTextContent={getTextContent}
							/>
						</Route>

						<Route path="/bekijken">
							<ViewClosetPage
								currentCloset={currentCloset}
								getTextContent={getTextContent}
							/>
						</Route>

						<Route path="/aanpassen">
							<ConfiguratorPage
								auth={auth}
								currentLocale={currentLocale}
								currentCloset={currentCloset}
								currentUser={currentUser}
								deleteProduct={deleteProduct}
								generateDownloadablePDF={generateDownloadablePDF}
								getTextContent={getTextContent}
								hideShoppingCartPane={hideShoppingCartPane}
								isClosetPaneDisabled={
									currentCloset &&
									currentCloset.boxes &&
									currentCloset.boxes.length > 0
								}
								isColorsPaneVisible={isColorsPaneVisible}
								isShoppingCartPaneVisible={isShoppingCartPaneVisible}
								materialOptions={materialOptions}
								onBeginDrag={handleBeginDrag}
								onClearButtonClick={handleClearButtonClick}
								onDropProduct={handleDropProduct}
								redInfoPopupIsVisible={redInfoPopupIsVisible}
								rotateProduct={rotateProduct}
								saveCloset={saveCloset}
								setCurrentCloset={setCurrentCloset}
								sideMenuRef={sideMenuRef}
								savePaneRef={savePaneRef}
								screenshotRef={screenshotRef}
								setBackgroundToColor={handleSetBackgroundToColor}
								setBackgroundToPhoto={handleSetBackgroundToPhoto}
								setIsColorsPaneVisible={setIsColorsPaneVisible}
								setRedInfoPopupIsVisible={setRedInfoPopupIsVisible}
							/>
						</Route>
					</Route>
				</Switch>
			</Flex>

			<ProductDragLayer />
		</Flex>
	);
}
